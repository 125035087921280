import image from './img/nosso-negocio.png';

export const OurJob = () => (
  <section className="flex items-center">
    <article className="w-full h-full p-4 lg:p-3">
      <h1 className="text-6xl">NOSSO NEGÓCIO</h1>
      <h2 className="text-lg md:text-3xl mt-3">
        TRANSFORMAMOS ENERGIA SOLAR EM ENERGIA ELÉTRICA – DE FORMA SUSTENTÁVEL E
        LIMPA.
      </h2>
      <h3 className="text-xl md:text-2xl text-brand font-bold mt-3">
        FUNCIONA ASSIM:
      </h3>
      <p className="text-xl mt-3">
        A energia do sol é captada por meio de
        placas solares, instaladas nas usinas da <strong>Enero Energia</strong>. Depois de convertida de solar para elétrica, a
        energia chega até a sua casa ou empresa, sem a necessidade de instalações ou intervenções
        estruturais. A distribuição é feita por meio das redes da Cemig, o que garante cobertura da Enero em
        todo o Estado de Minas Gerais.
      </p>
    </article>
    <figure className="hidden md:block w-1/2 lg:w-auto">
      <img src={image} alt="" />
    </figure>
  </section>
);
