import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { MenuIcon } from '../../Icons';
import { Button, Popover, List, ListItem } from '@material-ui/core';
import React from 'react';
import logoSVG from '../../svg/enero-logo-hor.svg';

type MenuProps = {
  transparent?: boolean;
  showLogo?: boolean;
};

const items = [
  {
    label: 'Início',
    path: `/`,
  },
  {
    label: 'Nossa história',
    path: `/nossa-historia`,
  },
  {
    label: 'Nosso negócio',
    path: `/nosso-negocio`,
  },
  {
    label: 'Nossas usinas',
    path: `/a-usina`,
  },
  {
    label: 'Como Contratar',
    path: `/como-contratar`,
  }
];

export const Menu = ({ transparent = false, showLogo = true }: MenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  return (
    <nav
      color="inherit"
      className={clsx('mt-0 p-3 relative', {
        'shadow-md': !transparent,
        'bg-white': !transparent,
        'fixed': !transparent,
        'p-5': !showLogo,
      })}>
      <div
        className={clsx('flex container mx-auto  items-center', {
          'justify-between': showLogo,
          'justify-end': !showLogo,
        })}>
        <span className={clsx({ hidden: !showLogo })}>
          <img src={logoSVG} alt="Enero Logo" />
        </span>
        <ul className="xl:block hidden">
          {items.map(item => (
            <li
              key={item.label}
              className="inline-block ml-2 px-2 font-bold text-brand-dark">
              <Link key={item.label} to={item.path} className="hover:underline">
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
        <span className="xl:hidden">
          <Button onClick={evt => setAnchorEl(evt.currentTarget)}>
            <span className="mr-2 hidden sm:block">Navegue aqui</span>
            <MenuIcon />
          </Button>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <List>
              {items.map(item => (
                <ListItem key={item.label}>
                  <Link
                    key={item.label}
                    to={item.path}
                    className="hover:underline"
                    onClick={() => setAnchorEl(null)}>
                    {item.label}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Popover>
        </span>
      </div>
    </nav>
  );
};
