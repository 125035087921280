import image from './new-map.jpeg';

export const Facilities = () => (
  <section className="flex flex-col sm:flex-row items-center py-10">
    <figure className="w-auto">
      <img src={image} alt="mapa" />
    </figure>
    <article className="p-6">
      <h1 className="text-6xl">NOSSAS USINAS</h1>
      <p className="text-xl mt-5">
        As usinas da Enero Energia ficam nos munícipios de Francisco Sá e Capitão Eneás, no
        Norte de Minas Gerais. O local foi escolhido por ser uma região com alto índice de incidência solar,
        permitindo a eficiência na captação de energia e gerando emprego em uma região carente.
      </p>
    </article>
  </section>
);
