import logo from '../../svg/enero-logo.svg';
import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import { AttachIcon, PhotoIcon } from '../../Icons/Icons';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import axios from 'axios';

type Inputs = {
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
  attachment?: FileList;
};

// eslint-disable-next-line no-useless-escape
const validEmailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
const brazilianPhoneNumberRegex = /^(?:\+55\s?)?(?:\(?\d{2}\)?\s?)?(?:9\d{4}[-.\s]?\d{4})$/;

type ContactFormProps = {
  hideLogo?: boolean;
}

export const ContactForm = ({hideLogo = false}: ContactFormProps) => {

  const isDelevopment = process.env.NODE_ENV === 'development';
  const formConfig = isDelevopment ? {
    values: {
      name: 'Neto Leal',
      email: 'netoleal@gmail.com',
      phoneNumber: '11 98727 2987',
      message: 'Testando 123',
    }
  } : {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>(formConfig);
  const [sending, setSending] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [postError, setPostError] = React.useState(false);
  const [postErrorMessage, setPostErrorMessage] = React.useState('');

  const errorKeys = new Set(Object.keys(errors));

  async function submitForm(data: Inputs) {
    setSending(true);
    setSuccess(false);
    setPostError(false);

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if(typeof value === 'string') {
        formData.append(key, value);
      } else if (value && value.length > 0) {
        formData.append(key, value[0]);
      }
    });

    const host = isDelevopment ? 'http://localhost:9000' : '';
    let response;

    try {
      response = await axios.post(host + '/contact_me.php', formData);

      if (response.status === 200) {
        setSuccess(true);
      } else {
        setPostError(true);

        const responseMessage = response.data?.message;
        if (responseMessage) {
          setPostErrorMessage(responseMessage);
        }
      }
    } catch (e) {
      setPostError(true);
      if (e instanceof Error) {

        const responseMessage = response?.data?.message;
        if (responseMessage) {
          setPostErrorMessage(responseMessage);
        } else {
          setPostErrorMessage(e.message);
        }
      }

    } finally {
      setSending(false);
    }
  }

  return <form
        onSubmit={handleSubmit(submitForm)}
        className="py-10 flex flex-col p-2 sm:p-6 gap-4">
          {!hideLogo && (
            <section>
              <img src={logo} alt="enero" className="w-8/12 mb-10" />
              <h1 className="text-5xl">SEJA ENERO ENERGIA</h1>
            </section>
          )}
        <section className="flex flex-col gap-3">
          <h2 className="text-xl uppercase text-brand font-bold">
            Entre em contato com a gente
          </h2>
          <TextField
            label="Seu nome"
            variant="outlined"
            error={errorKeys.has('name')}
            inputProps={register('name', { required: true })}
          />
          <TextField
            label="Seu e-mail"
            variant="outlined"
            error={errorKeys.has('email')}
            inputProps={register('email', {
              required: true,
              pattern: validEmailRegex,
            })}
          />
          <TextField
            label="Seu telefone/whatsapp"
            variant="outlined"
            error={errorKeys.has('phoneNumber')}
            placeholder='(11) 99999-9999'
            inputProps={register('phoneNumber', {
              required: true,
              pattern: brazilianPhoneNumberRegex,
              
            })}
          />
          <TextField
            label="Escreva aqui sua mensagem"
            multiline={true}
            rows={5}
            error={errorKeys.has('message')}
            variant="outlined"
            inputProps={register('message', { required: true })}
          />
          <p>
            Anexar arquivo (PDF ou JPG) (Opcional)
          </p>
          <TextField
            type="file"
            variant="outlined"
            error={errorKeys.has('attachment')}
            helperText={errors.attachment?.message}
            inputProps={register('attachment', {
              required: false,
              validate: {
                acceptedFormats: (files) => {
                  const acceptedFormats = ['application/pdf', 'image/jpeg'];
                  if(!files || files.length === 0) {
                    return true;
                  }
                  if(! acceptedFormats.includes(files[0]?.type)){
                    return 'Formato de anexo não suportado';
                  }

                  return true;
                },
              },
            })}
          />

        </section>
        <section className="hidden flex-col gap-3">
          <h2 className="text-xl uppercase text-brand font-bold">
            Adiante uma simulação
          </h2>
          <p className="text-gray-600 text-sm">
            Nos envie uma conta de energia sua
          </p>
          <div className="grid grid-cols-2 gap-1">
            <Button
              variant="outlined"
              className={clsx({ hidden: !isMobileOnly })}>
              <div className="flex flex-col items-center gap-2 p-4">
                <PhotoIcon />
                Tirar foto
              </div>
            </Button>
            <Button variant="outlined">
              <div className="flex flex-col items-center gap-2 p-4">
                <AttachIcon />
                Enviar imagem
              </div>
            </Button>
          </div>
        </section>
        <Button variant="contained" disabled={sending} type="submit">
          Enviar
        </Button>
        {sending && <p className="text-gray-600">Enviando ...</p>}
        {success && (
          <p className="text-green-700">Obrigado! Recebemos sua mensagem!</p>
        )}
        {postError && (
          <p className="text-red-600">
            Ops! Erro ao enviar o formulário! <br />
            {postErrorMessage && <span>{postErrorMessage}</span>}
          </p>
        )}

        <address>
          Av. Getúlio Vargas , 887, sala 1101 - Funcionários
          <br />
          Belo Horizonte – MG
        </address>
      </form>
}