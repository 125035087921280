import React from 'react';
import './App.css';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from './Routes';
import { Menu } from './components/Menu';
import { BeClient } from './components/BeClient';
import { Partners } from './components/Partners';

function App() {
  const isHome = useRouteMatch({ path: '/' })?.isExact;

  return (
    <main className="flex flex-col min-h-screen">
      <Menu transparent={isHome} showLogo={!isHome} />
      <section className="container mx-auto px-3 sm:px-0 py-1 flex-grow items-stretch">
        <Routes />
      </section>
      <BeClient />
      <Partners />
      <footer className="flex-grow-0 container mx-auto py-5 px-3 sm:px-0 bg-whiteAlpha z-50">
        <div className="flex flex-row justify-between text-gray-300">
          <nav>
            <ul className='flex items-center gap-3'>
              <li>
                <a
                  href="https://www.instagram.com/enero.energia"
                  target="_blank"
                  rel="noreferrer"
                  className="flex gap-1 items-center text-gray-500 hover:text-black"
                  >

                  <i className="fab fa-instagram" />
                  <span>
                  Instagram @enero.energia
                  </span>
                </a>
              </li>
              |
              <li>
                <a
                  href="https://wa.me/+5531971084461"
                  target="_blank"
                  rel="noreferrer"
                  className="flex gap-1 items-center text-gray-500 hover:text-black"
                >
                  <i className="fab fa-whatsapp" />
                  <span>
                    WhatsApp +55 31 97108-4461
                  </span>
                </a>
              </li>
            </ul>
          </nav>
          <div>
            <span>
              © {new Date().getFullYear()}, <i className="fa fa-heart heart"></i> by Enero
            </span>
          </div>
        </div>
      </footer>
      <div className="fixed bottom-4 right-4">
        <a
          href="https://wa.me/+5531971084461"
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center w-12 h-12 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600"
        >
          <i className="fab fa-whatsapp text-2xl"></i>
        </a>
      </div>
    </main>
    
  );
}

export default App;
