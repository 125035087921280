import logoSVG from '../../svg/enero-logo.svg';
import side from '../../svg/side-logo.svg';

export const Home = () => (
  <section className="home flex flex-row justify-end sm:justify-between h-full items-center">
    <h1 className="sr-only">Enero Energia</h1>
    <figure className="w-1/3 hidden sm:flex">
      <img src={side} alt="" className="w-full h-full" />
    </figure>
    <div className="sm:w-1/2 w-full flex flex-col animate-fade-in-up py-20 items-end">
      <img src={logoSVG} alt="Enero Energia" className="w-4/5" />
      <h2 className="mt-7 text-center text-brand text-4xl font-bold">
        ECONOMIA TODO MÊS NA CONTA DE LUZ
      </h2>
      <p className="text-2xl text-center mt-2">
        Energia mais barata para sua casa ou empresa
      </p>
    </div>
  </section>
);
