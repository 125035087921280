import side from '../../svg/side-logo-right.svg';
import { ContactForm } from './ContactForm';


export const Contact = () => {
  return (
    <section className="flex flex-row justify-between items-center">
      <ContactForm />    
      <img src={side} alt="" className="w-1/3 hidden sm:block" />
    </section>
  );
};
