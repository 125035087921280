import { Switch, Route } from 'react-router-dom';
import {
  Contact,
  Facilities,
  History,
  Home,
  HowToHire,
  OurJob,
  Partners,
  Simulations,
} from '../pages';

export const Routes = () => (
  <Switch>
    <Route path={`/contato`} component={Contact} />
    <Route path={`/a-usina`} component={Facilities} />
    <Route path={`/nossa-historia`} component={History} />
    <Route path={`/como-contratar`} component={HowToHire} />
    <Route path={`/nosso-negocio`} component={OurJob} />
    <Route path={`/clientes-parceiros`} component={Partners} />
    <Route path={`/na-pratica`} component={Simulations} />
    <Route path={`/`} component={Home} />
  </Switch>
);
