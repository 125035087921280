import starImg from '../../svg/enero-star.svg';

export const History = () => (
  <section className="relative flex items-center flex-col md:flex-row py-10">
    <img src={starImg} alt="" className="w-1/5 md:w-2/3" />
    <div className="p-4 z-10 md:w-2/3">
      <h1 className="text-6xl">NOSSA HISTÓRIA</h1>
      <p className="text-xl mt-2">
        A <strong>Enero Energia</strong> nasceu do desejo de impactar positivamente o
        território em que ocupa por meio da geração de energia limpa e sustentável. 
        Parte do grupo RO2 Engenharia e Construção, a <strong>Enero</strong> se destaca por eliminar
        burocracias e oferecer resultados concretos, ela traz em seu DNA a inovação e a
        confiabilidade conquistadas pela atuação de mais de duas décadas da RO2 no segmento de edifícios residenciais e comerciais de alto padrão.
      </p>
    </div>
  </section>
);
