import img from './simulations.png';

const Bold: React.FC = ({ children }) => (
  <strong className="text-3xl font-bold text-brand">{children}</strong>
);

export const Simulations = () => (
  <section className="flex items-center py-10">
    <figure className="hidden sm:block">
      <img src={img} alt="" />
    </figure>
    <article className="p-6">
      <h1 className="text-6xl"> NA PRÁTICA</h1>
      <h2 className="text-3xl mt-3">VAMOS FAZER UMA SIMULAÇÃO*</h2>
      <p className="text-xl  mt-3">
        Situação atual da conta da Cemig <Bold>R$ 2 mil</Bold> Situação após
        contratação da Enero Energia Você paga a taxa minima da Cemig{' '}
        <Bold>R$ 200,00</Bold>
        Mais <Bold>R$ 1.512</Bold> para a Enero, totalizando{' '}
        <Bold>R$ 1.712</Bold> Ou seja, uma economia de <Bold>R$ 288,00</Bold>{' '}
        por mês <Bold>R$ 3.456,00</Bold> por ano.
      </p>
      <p className="mt-4 text-gray-400">*DADOS ESTIMADOS</p>
    </article>
  </section>
);
