import { ContactForm } from '../Contact/ContactForm';
import img from './howto.png';

export const HowToHire = () => (
  <section className="flex items-center py-10">
    <figure className="hidden sm:block">
      <img src={img} alt="" />
    </figure>
    <article className="p-6">
      <h1 className="text-4xl sm:text-6xl">COMO CONTRATAR?</h1>
      <h2 className="text-3xl mt-3 text-brand">É simples!</h2>
      <p className="text-xl  mt-3">
        Nosso processo é simples e eficaz. Primeiro, enviamos
        uma simulação personalizada para mostrar a economia que você pode obter. Em seguida, solicitamos
        alguns documentos necessários para prosseguir. 
      </p>
      <p className="text-xl mt-3">
        Após isso, você recebe nosso contrato digital para
        assinatura, e nossa equipe revisa para garantir que tudo esteja correto. 
      </p>
      <p className="text-xl mt-3">
        Por fim, você passará a
        receber a conta da Enero e a sua conta de Cemig, agora apenas com a taxa mínima. Somando as duas,
        você ainda assim pagará menos!
      </p>
      <ContactForm hideLogo />
    </article>
  </section>
);
